import { styled } from '@material-ui/core/styles';
import { ButtonBase } from '@material-ui/core';


const ImageButton = styled(ButtonBase)(({ theme }) => ({
	position: 'relative',
	height: 200,
	[theme.breakpoints.down('sm')]: {
	  width: '100% !important', // Overrides inline-style
	  height: 150,
	},
	'&:hover, &.Mui-focusVisible': {
	  zIndex: 1,
	  '& .MuiImageBackdrop-root': {opacity: 0.15,},
	  '& .MuiImageMarked-root': {opacity: 0,},
	  '& .MuiTypography-root': {border: '4px solid currentColor',},
	},
      }));
      
      const ImageSrc = styled('span')({
	position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, backgroundSize: 'cover', backgroundPosition: 'center 40%',
      });
      
      const Image = styled('span')(({ theme }) => ({
	position: 'absolute',  left: 0, right: 0, top: 0, bottom: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', color: theme.palette.common.white,
      }));
      
      const ImageBackdrop = styled('span')(({ theme }) => ({
	position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, backgroundColor: theme.palette.common.black, opacity: 0.4, transition: theme.transitions.create('opacity'),
      }));
      
      const ImageMarked = styled('span')(({ theme }) => ({
	height: 3, width: 18, backgroundColor: theme.palette.common.white, position: 'absolute', bottom: -2, left: 'calc(50% - 9px)', transition: theme.transitions.create('opacity'),
      }));

      export {ImageButton, ImageSrc, Image,ImageBackdrop, ImageMarked } 